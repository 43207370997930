<template>
  <div id="cotizacion" class="layout--main py-10 calculadora-inmob">
    <div class="row">

        <button
					@click="regresar()" 
					type="button" 
					class="btn regresar w-48 flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="14"
            viewBox="0 0 30 22"
            fill="none"
          >
            <path
              d="M27.5 22V15.1643C27.5 13.75 26.9722 12.5452 25.9167 11.55C24.8611 10.5548 23.5833 10.0571 22.0833 10.0571H4.75L11.1667 16.1071L9.41667 17.7571L0 8.87857L9.41667 0L11.1667 1.65L4.75 7.7H22.0833C24.25 7.7 26.1111 8.42679 27.6667 9.88036C29.2222 11.3339 30 13.0952 30 15.1643V22H27.5Z"
              fill="#FF7150"
            />
          </svg>

          <span class="ml-4 regresar"> Regresar </span>
        </button>

    </div>

    <div class="container mt-5 md:mt-12">
      <div class="row">Cotización rápida</div>

      <div class="row mt-8 mb-8 p-8 pb-8 bg-calculadora">
        <div class="row">{{ tituloSeccion }}</div>

        <!-- simula tab links -->
        <div
					v-if="!mostrarFormCliente" 
					class="col-12">
          <div class="list-group flex-row flex-wrap justify-between md:flex-no-wrap calculadora-inmob-list" id="list-tab" role="tablist">
            <a
              @click="destinoActual(1)"
              class="list-group-item m-8 list-group-item-action flex flex-col items-center text-center lista-opciones-cal active"
              id="list-home-list"
              data-bs-toggle="list"
              href="#list-casa"
              role="tab"
              aria-controls="list-home"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="55"
                  viewBox="0 -960 960 960"
                  width="55"
                  fill="#006D4E"
                >
                  <path
                    d="M770-355v-322L560-824 350-677v129h-60v-159l270-193 270 193v352h-60ZM560-824Zm20 204h40v-40h-40v40Zm-80 0h40v-40h-40v40Zm80 80h40v-40h-40v40Zm-80 0h40v-40h-40v40Zm66 478-311-89v57H40v-394h309l255 96q27 10 45.5 32.5T668-295h114q42 0 70 30t28 81v26L566-62Zm-466-92h94v-274h-94v274Zm462 30 256-78q-6-19-15-26t-21-7H575q-30 0-55.5-4T471-250l-81-25 22-58 73 24q25 8 47.5 11t71.5 3q0-12-4.5-23.5T584-335l-245-93h-84v214l307 90ZM194-291Zm410-4Zm-410 4Zm61 0Z"
                  />
                </svg>
              </div>

              <div>Cr&eacute;dito para casa</div>
            </a>

            <a
              @click="destinoActual(5)"
              class="list-group-item m-8 list-group-item-action flex flex-col items-center text-center lista-opciones-cal"
              id="list-profile-list"
              data-bs-toggle="list"
              href="#list-terreno"
              role="tab"
              aria-controls="list-profile"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="55"
                  viewBox="0 -960 960 960"
                  width="55"
                  fill="#006D4E"
                >
                  <path
                    d="M303-80v-149H0l189-274H94l266-377 120 170 120-170 266 377h-94l188 274H658v149H543v-149H418v149H303Zm377-209h165L656-563h89L600-769l-80 115 106 151h-94l148 214Zm-564 0h489L416-563h89L360-769 215-563h90L116-289Zm0 0h189-90 290-89 189-489Zm564 0H532h94-106 225-89 189-165Zm-137 60h115-115Zm178 0Z"
                  />
                </svg>
              </div>

              <div>Cr&eacute;dito para terreno</div>
            </a>

            <a
              @click="destinoActual(2)"
              class="list-group-item m-8 list-group-item-action flex flex-col items-center text-center lista-opciones-cal"
              id="list-messages-list"
              data-bs-toggle="list"
              href="#list-construir"
              role="tab"
              aria-controls="list-messages"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="55"
                  viewBox="0 -960 960 960"
                  width="55"
                  fill="#006D4E"
                >
                  <path
                    d="M768-120 517-371l57-57 251 251-57 57Zm-581 0-57-57 290-290-107-107-23 23-44-44v85l-24 24-122-122 24-24h86l-48-48 131-131q17-17 37-23t44-6q24 0 44 8.5t37 25.5L348-699l48 48-24 24 104 104 122-122q-8-13-12.5-30t-4.5-36q0-53 38.5-91.5T711-841q15 0 25.5 3t17.5 8l-85 85 75 75 85-85q5 8 8.5 19.5T841-709q0 53-38.5 91.5T711-579q-18 0-31-2.5t-24-7.5L187-120Z"
                  />
                </svg>
              </div>

              <div class="text-center">Cr&eacute;dito para construir</div>
            </a>

            <a
              @click="destinoActual(19)"
              class="list-group-item m-8 list-group-item-action flex flex-col items-center text-center lista-opciones-cal"
              id="list-settings-list"
              data-bs-toggle="list"
              href="#list-hipoteca"
              role="tab"
              aria-controls="list-settings"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="55"
                  viewBox="0 -960 960 960"
                  width="55"
                  fill="#006D4E"
                >
                  <path
                    d="M480-40q-112 0-216-66T100-257v137H40v-240h240v60H143q51 77 145.5 138.5T480-100q78 0 147.5-30t121-81.5Q800-263 830-332.5T860-480h60q0 91-34.5 171T791-169q-60 60-140 94.5T480-40Zm-29-153v-54q-45-12-75.5-38.5T324-358l51-17q12 38 42.5 60t69.5 22q40 0 66.5-19.5T580-364q0-33-25-55.5T463-470q-60-25-90-54t-30-78q0-44 30-75t80-38v-51h55v51q38 4 66 24t45 55l-48 23q-15-28-37-42t-52-14q-39 0-61.5 18T398-602q0 32 26 51t84 43q69 29 98 61t29 83q0 25-9 46t-25.5 36Q584-267 560-257.5T506-245v52h-55ZM40-480q0-91 34.5-171T169-791q60-60 140-94.5T480-920q112 0 216 66t164 151v-137h60v240H680v-60h137q-51-77-145-138.5T480-860q-78 0-147.5 30t-121 81.5Q160-697 130-627.5T100-480H40Z"
                  />
                </svg>
              </div>

              <div class="text-center">Cr&eacute;dito para hipoteca</div>
            </a>
          </div>
        </div>

        <CalculadoraCredito 
					v-if="!mostrarFormCliente" 
					:destino="destino" 
					v-on:mostrarCliente="guardaDestino($event)" />

        <CalculadoraCliente 
					v-if="mostrarFormCliente" />

      </div>

    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "Calculadora",
  components: {
    CalculadoraCredito: defineAsyncComponent(() => import("@/views/CasoInmobiliaria/nuevasVistas/Calculadora/CalculadoraCredito.vue")),
    CalculadoraCliente: defineAsyncComponent(() => import("@/views/CasoInmobiliaria/nuevasVistas/Calculadora/CalculadoraCliente.vue")),
  },
  data() {
    return {
			tituloSeccion: '',
      caso: {},
      destino: 1,
			mostrarFormCliente: false,
			ubicacion: '',
    };
  },
	mounted() {
		// console.log("CreandoCalculado");
		if (localStorage.getItem('calculadoraRegresa')) {
			this.ubicacion = localStorage.getItem('calculadoraRegresa');

			// validando en donde esta
			if (this.ubicacion == 'Cliente') {
				// console.log( 'Vista datos cliente' );
				this.mostrarFormCliente = true;
				this.tituloSeccion = 'Datos de tu cliente';
			}

			if (this.ubicacion == 'Destino') {
				// console.log( 'Vista destino' );
				this.mostrarFormCliente = false;
				this.tituloSeccion = 'Destino';
			}

		} else {
			// acaba de ingresar al modulo
			localStorage.setItem('calculadoraRegresa', 'Destino');
			this.tituloSeccion = 'Destino';
		}
    this.activaPanel()
		this.seccionActiva();
	},
  methods: {
    destinoActual(intDestino) {
      this.destino = intDestino;
      // console.log("Destino actual", this.destino);
    },
		// enviarCotizacion(cotizacion) {
		// 	this.caso = cotizacion;
		// 	this.mostrarFormCliente = cotizacion.mostrarFormCliente;
		// 	this.tituloSeccion = cotizacion.tituloSeccion;

		// 	console.log( 'calculadora', this.caso );
		// },
		guardaDestino(cotizacion) {
			// console.log( 'Calculadora: ', cotizacion );
			localStorage.setItem('calculadoraDestino', JSON.stringify(cotizacion)); // guarda info capturada
			localStorage.setItem('calculadoraRegresa', 'Cliente');
			this.ubicacion = 'Cliente';
			this.tituloSeccion = 'Datos de tu cliente';
			this.mostrarFormCliente = true;
		},
    activaPanel(){
      this.$emit('actualiza_panel', 'HomeInmo', 'Calculadora')
    },
    updatePanel(){
      console.log("he actualizado bande");
      this.$emit('actualiza_panel', 'Calculadora')
    },
		regresar() {

			switch (this.ubicacion) {
				case 'Cliente':
					// esta en cliente, va a destino
					this.mostrarFormCliente = false;
					this.tituloSeccion = 'Destino';
					this.ubicacion = 'Destino';
					localStorage.setItem('calculadoraRegresa', 'Destino');
					
					// provisional
					setTimeout(() => {
						this.seccionActiva(); // recargar
					}, 1000);

					break;
				case 'Destino':
					// esta en destino, va a salir del modulo
					localStorage.removeItem('casoInmobiliario');
					localStorage.removeItem('calculadoraRegresa');
					localStorage.removeItem('calculadoraDestino');
					localStorage.removeItem('calculadoraCliente');
					this.$router.push('/homeInmo');
          this.updatePanel()
					break;
        default:
          this.$router.push('/homeInmo');
          localStorage.removeItem('casoInmobiliario');
					localStorage.removeItem('calculadoraRegresa');
					localStorage.removeItem('calculadoraDestino');
					localStorage.removeItem('calculadoraCliente');
          this.updatePanel()
          break;
			}
			// console.log( 'regresaBtn', this.caso );
		},
		seccionActiva() {
			// busco si hay un destino guardado
			if (JSON.parse(localStorage.getItem("calculadoraDestino"))) {
				let dataLocalStorage = JSON.parse(localStorage.getItem("calculadoraDestino"));
				this.destino = dataLocalStorage.destino;
      }
			// asigna la clase active al destino correcto
			document.getElementById('list-home-list').classList.remove('active');
			document.getElementById('list-profile-list').classList.remove('active');
			document.getElementById('list-messages-list').classList.remove('active');
			document.getElementById('list-settings-list').classList.remove('active');

			switch (this.destino) {
				case 1:
					document.getElementById('list-home-list').classList.add('active');
					break;
				case 5:
					document.getElementById('list-profile-list').classList.add('active');
					break;
				case 2:
					document.getElementById('list-messages-list').classList.add('active');
					break;
				case 19:
					document.getElementById('list-settings-list').classList.add('active');
					break;
			}
			
		}
  },
};
</script>

<style lang="scss" scoped>
.bg-calculadora {
  background-color: #f2f2f2;
  border-radius: 20px;
}
.lista-opciones-cal {
  border-radius: 10px;
}

.list-group-item.active {
  z-index: 2;
  color: #000;
  background-color: #d9d9d9;
  border-color: transparent;
}
.list-group-item {
  border: 0px;
}
.btn-blue {
  color: #fff;
  background-color: #079def !important;
  border-color: #079def;
  margin-left: 0px !important;
}
.form-check-label {
  margin-top: 0px !important;
}
select {
  padding: 0.35rem !important;
}
.regresar{
  background-color: #fff;
  color: #FF7150;
  border: none;
}
.regresar:focus {
	box-shadow: none;
}

/********** Nuevos estilos responsivos **********/
@media screen and (max-width: 767px) {
  .calculadora-inmob-list {
    padding: 1rem 0;
  }
  .calculadora-inmob-list .lista-opciones-cal {
    width: calc(50% - 1.5rem);
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-width: 414px) {
  .calculadora-inmob .bg-calculadora {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .calculadora-inmob-list .lista-opciones-cal {
    width: calc(50% - 0.5rem);
  }
}
</style>